
// Remove ring item (called on drag out of bounds and drag to dropzone)
function removeRingItem() {

	var removedIndex = $('.interact-item-selected').index('.interact-item'); // Get index of removed item
	var removedHandle = $('.interact-item-selected').attr('data-handle'); // Get handle of removed item

  $('.interact-item-selected').addClass('removed'); // Fade out removed item
  $('.ring-selection-item.current').addClass('removed'); // Fade out stack item

  $('.interact-item.removed').removeClass('interact-item-selected'); // Make removed item not selected
  $('.ring-selection-item.removed').removeClass('current'); // Make stack item not selected

	// Only apply if the removed leave only 1
	if ($('.interact-item').length > 1) {

		var newCurrentIndex = removedIndex + 1; //  next one up the line
		if ($('.interact-item').length == removedIndex + 1) { // if the removed is the top item
			newCurrentIndex = removedIndex - 1; // previous one in line
		}

		var currentRingItem = $('.interact-item').eq(newCurrentIndex);
    currentRingItem.addClass('interact-item-selected'); // Make previous item selected

		var currentRingSelectionItem = $('#rv-selection-stack .ring-selection-item').eq(newCurrentIndex);
    currentRingSelectionItem.addClass('current'); // Make previous stack item current

    $('#rv-add-ring-btn').removeClass('disabled'); // Enable add-ring button

		// Check if new selected is resizable
		if (currentRingItem.attr('data-resizable') == 'true') {
			$('#rv-resize-btn').addClass('active');
		} else {
			$('#rv-resize-btn').removeClass('active');
		}

		// Check metal
		if (currentRingItem.attr('data-alt-metal') == 'true') {
			$('#rv-metal-btn').removeClass('disabled');
		} else {
			$('#rv-metal-btn').addClass('disabled');
		}
		if (currentRingItem.attr('data-handle').indexOf('white-gold') >= 0) {
			$('#rv-metal-btn').addClass('white-gold');
		} else {
			$('#rv-metal-btn').removeClass('white-gold');
		}

		// Check band
		if (currentRingItem.attr('data-alt-band') == 'true') {
			$('#rv-band-btn').removeClass('disabled');
		} else {
			$('#rv-band-btn').addClass('disabled');
		}
		if (currentRingItem.attr('data-handle').indexOf('polished') >= 0) {
			$('#rv-band-btn').addClass('polished');
		} else {
			$('#rv-band-btn').removeClass('polished');
		}

    //navigator.vibrate([30]); // Haptic feedback

	}

  // Remove removed item from DOM after fade-out time
  setTimeout( function() {
    $('.interact-item.removed').remove(); // Remove removed item
    $('.ring-selection-item.removed').remove(); // Remove removed stack item
  }, 300);


  interactItemCount -= 1; // Decrement item count
  itemInMotion = true; // Declare item in motion so that these statements are called only once
  if (interactItemCount <= 0) {  // If no rings are present
		$('#rv-save-btn, #rv-edit-btn, #rv-cta-btn').addClass('disabled');
		$('#rv-resize-btn').removeClass('active'); // Hide Clear button
    //$('#rv-tone-btn').removeClass('active'); // Hide Tone button
    $('#rv-recapture-btn').removeClass('confirm');
  	$('#rv-info-layer').removeClass('rings-active'); // Update Info layer
    setTimeout( function() {
      $('#rv-control-layer #rv-add-ring-btn').addClass('highlight'); // Add highlight
    }, 1000);
  }

	//  Update CTA drawer
	updateCtaList(removedHandle, 'remove');

} // removeRingItem()
