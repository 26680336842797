
// Save

function saveImageIOS() {

  $('#rv-save-drawer').find('.download-image').remove();
  $('#rv-save-drawer').addClass('active loading');
  $('#rv-export-layer').addClass('active');

  var exportLayer = document.getElementById('rv-export-layer');
  var saveDrawer = document.getElementById('rv-save-drawer');
  var saveContainer = saveDrawer.querySelector('.drawer-main .inner');
  var composition = document.getElementById('rv-interact-layer');
  var watermark = document.getElementById('rv-watermark');

  var svgImg = new Image();
  var renderWidth = window.innerWidth;
  var renderHeight = window.innerHeight;
  function svgFilter(node) {
    return (node.tagName !== 'i');
  }
	var svgRenderOptions = {
		width: renderWidth,
		height: renderHeight,
    filter: svgFilter
	}
  if (downloadWatermark == true) {
    watermark.classList.add('active');
  }

  setTimeout( function() {
    domtoimage.toSvg(composition, svgRenderOptions).then(function (dataUrl) {
  	  svgImg.src = dataUrl;
  	  exportLayer.appendChild(svgImg);
      svgImg.onload = function() {
        makeJpg(svgImg);
      };
    }).catch(function (error) {
        console.error('oops, something went wrong!', error);
    });
  }, 500);


  function makeJpg(svgImg) {

    var jpgRenderOptions = {
			width: renderWidth,
			height: renderHeight,
      scale: 2
		}

    domtoimage.toJpeg(exportLayer, jpgRenderOptions).then(function (dataUrl) {
      var jpgImg = new Image();
      jpgImg.src = dataUrl;
      jpgImg.onload = function() {
        jpgImg.classList.add('download-image');
        saveContainer.appendChild(jpgImg);
        setTimeout( function() {
          svgImg.remove();
          watermark.classList.remove('active');
          saveDrawer.classList.remove('loading');
        }, 1000);
      };
    });

  }

} // end saveImageIOS()







function saveImageAndroid() {

  var exportLayer = document.getElementById('rv-export-layer');
  var composition = document.getElementById('rv-interact-layer');
  var saveDrawer = document.getElementById('rv-save-drawer');
  var watermark = document.getElementById('rv-watermark');
	var renderOptions = {
		width: window.innerWidth,
		height: window.innerHeight,
		scale: 2
	}
  if (downloadWatermark == true) {
    saveDrawer.classList.add('active', 'semi-active', 'loading', 'load-only');
    watermark.classList.add('active');
  }

	// To PNG
	domtoimage.toJpeg(composition, renderOptions).then(function (dataUrl) {

		var img = new Image();
	  img.src = dataUrl;
	  exportLayer.appendChild(img);
    img.onload = function() {
      var downloadFileName = downloadFilePrefix + '-' + getDateTime() + '.jpg';
      directDownload(img.src, downloadFileName);
      img.remove();
      watermark.classList.remove('active');
      setTimeout( function() {
        saveDrawer.classList.remove('active', 'semi-active', 'loading');
      }, 1000);
    };

  }).catch(function (error) {
      console.error('oops, something went wrong!', error);
  });

} // end saveImageAndroid()


function directDownload(dataUrl, fileName) {
	var link = document.createElement('a');
  link.download = fileName;
  link.href = dataUrl;
	//link.target = '_blank';
  link.click();
}







// Export composition as flattened image
// Some refs:
// https://weworkweplay.com/play/saving-html5-canvas-as-image/
// https://www.geeksforgeeks.org/how-to-take-screenshot-of-a-div-using-javascript/
// https://medium.com/@dis_is_patrick/javascript-screenshots-bbd1cff7a32f
// https://w3lessons.info/how-to-capture-webpage-screenshot-using-javascript
// https://www.npmjs.com/package/dom-to-image
// https://github.com/tsayen/dom-to-image
// https://github.com/1904labs/dom-to-image-more
// https://stackoverflow.com/questions/10721884/render-html-to-an-image
