

function addRing(handle) {

  // Get default image
  var thisProductObjIndex = getProductObjIndex(handle); // get index of this product in JSON
  var thisProductCard = $('#rv-ring-list-drawer').find('.product-card[data-handle="' + handle + '"]');
  var thisRingImgSrc = thisProductCard.children('img').attr('src');
	var thisRingImgFile = thisRingImgSrc.substring(thisRingImgSrc.lastIndexOf('/')+1); // Get file from path
	var thisRingCutImage = rvProducts[thisProductObjIndex].image_front_cut;

	// Create ring item
  $('#rv-interact-layer').append(
    '<div class="interact-item draggable active interact-item-selected new-interact-item" data-handle="' + handle + '" data-resizable="false" data-alt-metal="false" data-alt-band="false"><div class="scale-element"><img class="default" src="' + productImagesPath + thisRingCutImage + '"></div></div>'
  );

	var thisRingItem = $('.new-interact-item');

	// Check for resizable image parts
  if (checkResizable(handle) == true){
		// Append image parts
		var imageParts = '<img class="ring-part ring-part-band-left" src="' + productImagesPath + rvProducts[thisProductObjIndex].image_part_band_left + '"/>' +
							 				'<img class="ring-part ring-part-band-right" src="' + productImagesPath + rvProducts[thisProductObjIndex].image_part_band_right + '"/>' +
						 					'<img class="ring-part ring-part-setting" src="' + productImagesPath + rvProducts[thisProductObjIndex].image_part_setting + '"/>';
		thisRingItem.children('.scale-element').append(imageParts);
		// Set resizable attrs
		thisRingItem.attr('data-resizable', true);
		thisRingItem.attr('data-resize-value', deafultResizeValue);
		// Apply resize (if resize has been previously applied)
		if (recentResizeValue < deafultResizeValue) {
			// Show ring part images
			thisRingItem.find('img.default').hide();
			thisRingItem.find('img.ring-part').show();
			// Apply resize
			var minResizePercentage = 20; // smallest possible size will be this % of default
			var resizeAmount = (1-recentResizeValue)*minResizePercentage/2;
			thisRingItem.find('.ring-part-band-left').css('transform', 'translateX(' + resizeAmount + '%)');
			thisRingItem.find('.ring-part-band-right').css('transform', 'translateX(' + -resizeAmount + '%)');
			thisRingItem.attr('data-resize-value', recentResizeValue);
		}
		// Enable resizable button
		$('#rv-resize-btn').removeClass('disabled');
  }
	else {
		$('#rv-resize-btn').addClass('disabled');
	}

  // Check for alt metal product
	if (getAltHandle(handle, 'metal')) {

  // if (rvProducts[thisProductObjIndex].alt_metal_handle === undefined ||
  //     rvProducts[thisProductObjIndex].alt_metal_handle === ''
  // ) {

    // Set alt metal attr
    thisRingItem.attr('data-alt-metal', true);
    // Enable alt metal button
    $('#rv-metal-btn').removeClass('disabled');
    if (handle.indexOf('white-gold') >= 0) {
      $('#rv-metal-btn').addClass('white-gold');
    } else {
      $('#rv-metal-btn').removeClass('white-gold');
    }
    //$('#rv-metal-btn').addClass('disabled');
  }
  else {
    // Set alt metal attr
    // thisRingItem.attr('data-alt-metal', true);
    // // Enable alt metal button
    // $('#rv-metal-btn').removeClass('disabled');
    // if (handle.indexOf('white-gold') >= 0) {
    //   $('#rv-metal-btn').addClass('white-gold');
    // } else {
    //   $('#rv-metal-btn').removeClass('white-gold');
    // }
    $('#rv-metal-btn').addClass('disabled');
	}

  // Check for alt band product
  if (rvProducts[thisProductObjIndex].alt_band_handle === undefined ||
      rvProducts[thisProductObjIndex].alt_band_handle === ''
  ) {
    $('#rv-band-btn').addClass('disabled');
  }
  else {
    // Set alt band attr
    thisRingItem.attr('data-alt-band', true);
    // Enable alt band button
    $('#rv-band-btn').removeClass('disabled');
    if (handle.indexOf('polished') >= 0) {
      $('#rv-band-btn').addClass('polished');
    } else {
      $('#rv-band-btn').removeClass('polished');
    }
	}

	// Apply (recent or default) angle and scale
	thisRingItem.find('.scale-element').css('transform', 'rotate(' + ringItemRecentAngle + 'deg)' + 'scale(' + ringItemRecentScale + ')');

  // Apply (recent or default) tones
  //thisRingItem.find('img').css('filter', 'brightness(' + brightnessVal + ') contrast(' + contrastVal + ') saturate(' + saturationVal + ') drop-shadow(0px 4px 12px rgba(0,0,0,' + shadowVal + '))');

  // Position/highlight interact item
  var thisRingItemWidth = thisRingItem.width();
  var thisRingItemHeight = thisRingItem.height();
  thisRingItem.css('left', (window.innerWidth/2 - thisRingItemWidth/2)  + "px");
  thisRingItem.css('top', (window.innerHeight/2 - thisRingItemHeight/2)  + "px");

  thisRingItem.removeClass('new-interact-item');

	// Make interactable
  stopInteractable();
  makeInteractable();
  // Increment count
  interactItemCount += 1;

  // Make all existing stack items not current
  $('#rv-selection-stack').children('.ring-selection-item').removeClass('current');
  // Append selection to stack as current
  $('#rv-selection-stack').append(
    '<div class="ring-selection-item current"><div class="inner" style="background-image: url(' + productImagesPath + thisRingCutImage + ')"></div></div>'
  );
  if (interactItemCount > 0) { // If any rings are added
    $('#rv-control-layer #rv-add-ring-btn').removeClass('highlight'); // Remove highlight
    $('#rv-save-btn, #rv-edit-btn, #rv-cta-btn').removeClass('disabled');
    $('#rv-recapture-btn').addClass('confirm'); // Apply a confirmation to this button's action
  }
  if (interactItemCount >= maxRings) { // Disable add-ring button if rings are at capacity
    $('#rv-add-ring-btn').addClass('disabled');
  }

  // Update CTA drawer
  updateCtaList(handle, 'add');

  // Update Info layer
  $('#rv-info-layer').addClass('rings-active');

	// Gesture guide
	var gestureGuideCookie = getCookie('rv-gesture-guide');
	if (gestureGuideCookie !== "closed"){
    $('#gesture-guide').find('img[data-src]').each( function() { // apply lazy loading on guide images
      $(this).attr('src', $(this).attr('data-src'));
    });
		setTimeout( function() {
	    $('#gesture-guide').addClass('active');
			// Close gesture guide (button)
			$('#gesture-guide').find('.btn').on('click', function(e) {
				setCookie('rv-gesture-guide', 'closed', guideExpiryDays); // set cookie
			});
	  },500);
	}

} // end addRing()
