

// Make RV ring list
function makeRingList(rvProducts) {
  var validProductStatusLower = validProductStatus.map(status => status.toLowerCase()); // Convert to lowercase to allow for case discrepancies 
  for (i = 0; i < rvProducts.length; i++){
    // Only allow valid status products
    if (validProductStatusLower.includes(rvProducts[i].status.toLowerCase())){
      // Image (lazy loading)
      var ringListItemImageSrc = '';
      if (rvProducts[i].image_front_default !== '') {
        ringListItemImageSrc = rvProducts[i].image_front_default;
      } else {
        ringListItemImageSrc = rvProducts[i].image_front_cut;
      }
      var ringListItemImage = '<img src="' + lazyPlaceholderImagePath + '" data-src="' + productImagesPath + ringListItemImageSrc + '">';
      // Title
      var ringListItemTitle = rvProducts[i].title;
      if (ringListItemTitle.indexOf(' - ')) {
        ringListItemTitle = ringListItemTitle.replace(' - ', '<span>') + '</span>';
      }
      ringListItemTitle = '<h4 class="title">' + ringListItemTitle + '</h4>';
      var productCard = '<div class="product-card" data-handle="' + rvProducts[i].handle + '">' + ringListItemImage + ringListItemTitle + '</div>';
      // Append
      $('#rv-ring-list-drawer').find('.drawer-main .grid').append('<div class="grid-item">' + productCard + '</div>');
    }
  }
} // end makeRingList()




// Reorder RV ring list (if product specified in URL)
function reorderRingList(urlProductHandle) {

  // Create marker to insert URL product and related products before
  $('#rv-ring-list-drawer').find('.drawer-main .grid').prepend('<div id="marker"></div>');

  // Move URL product to top position
  $('#rv-ring-list-drawer').find('.grid-item').each( function() {
    var thisGridItem = $(this);
    var productHandle = thisGridItem.children('.product-card').attr('data-handle');
    if (productHandle == urlProductHandle) {
      thisGridItem.addClass('url-product');
      $('#rv-ring-list-drawer').find('#marker').before(thisGridItem);
      return false;
    }
  });

  // Check for related products
  relatedProducts = rvProducts[getProductObjIndex(urlProductHandle)].related_handles;
  if (relatedProducts.length > 0) {
    // Add Related products heading
    $('#rv-ring-list-drawer').find('#marker').before('<h3>' + ringListRelatedProductsHeading + '</h3>');
    // Move related products to follow URL product
    for (j = 0; j < relatedProducts.length; j++){
      var thisGridItem = $('#rv-ring-list-drawer').find('.product-card[data-handle="' + relatedProducts[j] + '"]').parent();
      $('#rv-ring-list-drawer').find('#marker').before(thisGridItem);
    }
    // Add More products heading
    $('#rv-ring-list-drawer').find('#marker').before('<h3>' + ringListMoreProductsHeading + '</h3>');
  }

  $('#marker').remove(); // remove marker

} // end reorderRingList()
