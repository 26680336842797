
// Tone controls

// Use Tone sliders
// Ref: https://codepen.io/taye/pen/GgpxNq
interact('.filter-slider')
.draggable({
  origin: 'self',
  inertia: false,
  modifiers: [
    interact.modifiers.restrict({
      restriction: 'self'
    })
  ]
})
.on('dragmove', function (event) {  // call this listener on every dragmove

  // var thisSliderWidth = interact.getElementRect(event.target.parentNode).width;
  var thisSliderWidth = interact.getElementRect(event.target).width;
  var thisSliderVal = (event.pageX / thisSliderWidth).toFixed(2); // 2 decimal places (str)

  // Determine new tonal value
  // if (event.target.classList.contains('brightness')) {
  //   brightnessVal = (brightnessDefaultVal - brightnessRange) + (((brightnessDefaultVal + brightnessRange) - (brightnessDefaultVal - brightnessRange)) * thisSliderVal);
  // }
  // else if (event.target.classList.contains('contrast')) {
  //   contrastVal = (contrastDefaultVal - contrastRange) + (((contrastDefaultVal + contrastRange) - (contrastDefaultVal - contrastRange)) * thisSliderVal);
  // }
  // else if (event.target.classList.contains('saturation')) {
  //   saturationVal = (saturationDefaultVal - saturationRange) + (((saturationDefaultVal + saturationRange) - (saturationDefaultVal - saturationRange)) * thisSliderVal);
  // }
  // else if (event.target.classList.contains('shadow')) {
  //   shadowVal = (shadowDefaultVal - shadowRange) + (((shadowDefaultVal + shadowRange) - (shadowDefaultVal - shadowRange)) * thisSliderVal);
  // }

  // Set position of slider handle and apply to attributes
  event.target.style.paddingLeft = (thisSliderVal * 100) + '%';
  event.target.setAttribute('data-slider-value', thisSliderVal);

  applyFilters(filterValues, thisSliderVal);

});






function applyFilters(filterValues, filterSliderValue) {

  // Brightness
  var newBrightnessVal = filterValues.brightness;
  brightnessRange = Math.abs(parseFloat(filterValues.brightness) - brightnessDefaultVal);
  if (parseFloat(filterValues.brightness) >= brightnessDefaultVal) {
    newBrightnessVal = brightnessDefaultVal + (brightnessRange * filterSliderValue);
  } else {
    newBrightnessVal = brightnessDefaultVal - (brightnessRange * filterSliderValue);
  }

  // Contrast
  var newContrastVal = filterValues.contrast;
  contrastRange = Math.abs(parseFloat(filterValues.contrast) - contrastDefaultVal);
  if (parseFloat(filterValues.contrast) >= contrastDefaultVal) {
    newContrastVal = contrastDefaultVal + (contrastRange * filterSliderValue);
  } else {
    newContrastVal = contrastDefaultVal - (contrastRange * filterSliderValue);
  }

  // Saturation
  var newSaturationVal = filterValues.saturate;
  saturationRange = Math.abs(parseFloat(filterValues.saturate) - saturationDefaultVal);
  if (parseFloat(filterValues.saturate) >= saturationDefaultVal) {
    newSaturationVal = saturationDefaultVal + (saturationRange * filterSliderValue);
  } else {
    newSaturationVal = saturationDefaultVal - (saturationRange * filterSliderValue);
  }

  // Hue Rotation
  var newHueRotateVal = filterValues.hueRotate;
  hueRotateRange = Math.abs(parseFloat(filterValues.hueRotate) - hueRotateDefaultVal);
  if (parseFloat(filterValues.hueRotate) >= hueRotateDefaultVal) {
    newHueRotateVal = hueRotateDefaultVal + (hueRotateRange * filterSliderValue);
  } else {
    newHueRotateVal = hueRotateDefaultVal - (hueRotateRange * filterSliderValue);
  }

  // Sepia
  var newSepiaVal = filterValues.sepia;
  sepiaRange = Math.abs(parseFloat(filterValues.sepia) - sepiaDefaultVal);
  if (parseFloat(filterValues.sepia) >= sepiaDefaultVal) {
    newSepiaVal = sepiaDefaultVal + (sepiaRange * filterSliderValue);
  } else {
    newSepiaVal = sepiaDefaultVal - (sepiaRange * filterSliderValue);
  }

  // Overlay
  var newFilterOverlayOpacity = filterValues.overlayOpacity * filterSliderValue;

  // Apply filter to base image
  var baseImage = document.getElementById('rv-base-image-container');
  //baseImage = baseImage.getElementsByTagName('img');
  var baseImageFilterOverlay = baseImage.querySelector('.filter-overlay');
  baseImage.style.webkitFilter = 'brightness(' + newBrightnessVal + ') contrast(' + newContrastVal + ') saturate(' + newSaturationVal + ') hue-rotate(' + newHueRotateVal + 'deg) sepia(' + newSepiaVal + ')';
  baseImageFilterOverlay.style.opacity = newFilterOverlayOpacity;

}
