

function getCurrencySymbol(currencyCode) {
  if (currencyCode === 'EUR') {
    currencySymbol = '€';
  }
  if (currencyCode === 'GBP') {
    currencySymbol = '£';
  }
  if (currencyCode === 'JPY') {
    currencySymbol = '¥';
  }
  if (currencyCode === 'AUD' || currencyCode === 'CAD' || currencyCode === 'HKD' || currencyCode === 'NZD' || currencyCode === 'SGD' || currencyCode === 'USD') {
    currencySymbol = '$';
  }
  return currencySymbol;
}




function formatPrice(amount, commas) {
  var trailingZeroes = false;
  if (amount.indexOf('.00') >= 0) {
    trailingZeroes = true;
  }
  if (typeof amount === 'string') {
    amount = parseInt(amount);
  }
  if (trailingZeroes == true) {
    amount = amount.toFixed(2);
  }
  if (commas === true) {
    amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  amount = amount.replace('.00', ''); // Remove all decimals from price
  var formattedPrice = currencySymbol + amount;
  return formattedPrice;
}




function storeLocator(currencyCode) {
  if (currencyCode.toUpperCase() == 'GBP') {
    storeURL = ukStoreURL;
  }
  else {
    storeURL = defaultStoreURL;
  }
  return storeURL;
}




// Apply currency change
function applyCurrency(currency) {

  currencyCode = currency; // Update global currency code
  currencySymbol = getCurrencySymbol(currencyCode); // Update global currency symbol
  storeURL = storeLocator(currencyCode); // Check for store location based on currency

  // Change currency switch
  $('#rv-cta-drawer').find('.currency-switch').text(currencyCode);
  // Make currency item active
  $('.currency-select .currency-option').removeClass('active');
  $('.currency-select .currency-option[data-currency=' + currencyCode + ']').addClass('active');

  // Change CTA items
  $('#rv-cta-drawer').find('.drawer-main .grid .grid-item').each(function () {
    var thisCTAItem = $(this);
    var handle = thisCTAItem.find('.cta-item').attr('data-handle');
    var status = thisCTAItem.find('.cta-item').attr('data-status');
    if (status.toLowerCase() !== 'coming soon') {
      // Get price for current currency from store
      var productInCurrencyJson = storeURL + 'products/' + handle + '.json?currency=' + currencyCode;
      ajaxCall('GET', productInCurrencyJson, function (response) {
        var parsedResponse = JSON.parse(response); // Parse AJAX data
        var price = formatPrice(parsedResponse.product.variants[0].price, true);
        thisCTAItem.find('.price').text(price);
      });
    }
  });

}
