
// Check if product is resizable
// Takes product handle, returns true if resize image parts are present
function checkResizable(handle) {
  var index = getProductObjIndex(handle);
  if (
    rvProducts[index].image_part_setting !== '' &&
    rvProducts[index].image_part_band_left !== '' &&
    rvProducts[index].image_part_band_right !== ''
  ){
    return  true;
  }
  else {
    return false;
  }
}
