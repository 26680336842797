

function updateCtaList(handle, action, replacingHandle) {

  function makeCtaItem(handle) {
    var thisProductObjIndex = getProductObjIndex(handle); // get index of this product in JSON
    var thisProductObjStatus = rvProducts[thisProductObjIndex].status.toLowerCase();
    // Image
    var ctaItemImageSrc = '';
    if (rvProducts[thisProductObjIndex].image_front_default !== '') {
      ctaItemImageSrc = rvProducts[thisProductObjIndex].image_front_default;
    } else {
      ctaItemImageSrc = rvProducts[thisProductObjIndex].image_front_cut;
    }
    var ctaItemImage = '<div class="image"><img src="' + productImagesPath + ctaItemImageSrc + '"></div>';
    // Title
    var ctaItemTitle = rvProducts[thisProductObjIndex].title;
    if (ctaItemTitle.indexOf(' - ')) {
      ctaItemTitle = ctaItemTitle.replace(' - ', '<span>') + '</span>';
    }
    ctaItemTitle = '<h4 class="title">' + ctaItemTitle + '</h4>';
    // Price
    var ctaItemPrice = '<p class="price"></p>';
    if(thisProductObjStatus !== 'coming soon') { // Only display price if not "Coming Soon"
      ctaItemPrice = formatPrice(rvProducts[thisProductObjIndex].price, true);
      ctaItemPrice = '<p class="price">' + ctaItemPrice + '</p>';
    }
    // CTA link (dynamic onclick function)
    var ctaItemLink = '<a class="store-link" href="javascript:;" rel="noopener">' + ctaItemLinkText + '</a>';
    if(thisProductObjStatus == 'coming soon') { // Replace link with message for "Coming Soon" products
      ctaItemLink = '<span class="status-message">' + ctaItemComingSoonText + '</span>';
    }
    // Combine parts
    var ctaItem = `<div class="cta-item" data-handle=${handle} data-qty="1" data-status="${thisProductObjStatus}">
      ${ctaItemImage}
      ${ctaItemTitle}
      ${ctaItemPrice}
      ${ctaItemLink}
      </div>`;
    return ctaItem;
  }

  function addCtaItem(handle) {
    var ctaItemExists = false;
    $('#rv-cta-drawer').find('.drawer-main .grid .grid-item').each( function() {
      var thisCTAItem = $(this);
      if (thisCTAItem.children('.cta-item').attr('data-handle') == handle) {
        var thisCTAItemQty = parseInt(thisCTAItem.children('.cta-item').attr('data-qty'));
        thisCTAItem.children('.cta-item').attr('data-qty', thisCTAItemQty + 1);
        ctaItemExists = true; // prevent appending item to list
        return false;
      }
    });
    // Append to end of list
    if (ctaItemExists == false) {
      $('#rv-cta-drawer').find('.drawer-main .grid').append('<div class="grid-item">' + makeCtaItem(handle) + '</div>');
    }
    // Update CTA item price based on currency
    applyCurrency(currencyCode);
  }

  function removeCtaItem(handle) {
    $('#rv-cta-drawer').find('.drawer-main .grid .grid-item').each( function() {
      var thisCTAItem = $(this);
      if (thisCTAItem.children('.cta-item').attr('data-handle') == handle) {
        var thisCTAItemQty = parseInt(thisCTAItem.children('.cta-item').attr('data-qty'));
        if (thisCTAItemQty > 1) {
          thisCTAItem.children('.cta-item').attr('data-qty', thisCTAItemQty - 1);
        } else {
          thisCTAItem.remove();
        }
        return false;
      }
    });
  }


  // Apply action
  if (action == 'add') {
    addCtaItem(handle);
  }
  else if (action == 'remove') {
    removeCtaItem(handle);
  }
  else if (action == 'replace') {
    removeCtaItem(handle);
    addCtaItem(replacingHandle);
  }


} // end updateCtaList()
