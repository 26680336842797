
// Intersection Observer - image load
// Ref: https://css-tricks.com/a-few-functional-uses-for-intersection-observer-to-know-when-an-element-is-in-view/
// Ref: https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video?authuser=0


function lazyImages(root, rootMargin) {

  if ('IntersectionObserver' in window) { // If IntObv is compatible with browser
    var lazyImageObserver = new IntersectionObserver(function(entries) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          //console.log('image');
          var lazyImage = entry.target;
          if (lazyImage.src) {
            lazyImage.src = lazyImage.dataset.src;
          }
          if (lazyImage.srcset) {
            lazyImage.srcset = lazyImage.dataset.srcset;
          }
          lazyImage.setAttribute('data-lazy-complete', 'true'); // Mark as complete
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    }, {root: root, rootMargin: rootMargin, threshold: 0});
    // Watch all <img> and <source> with data-src/data-srcset
    document.querySelectorAll('img[data-src], source[data-srcset]').forEach(function(img) {
      if (!img.hasAttribute('data-lazy-complete')) { // Only on incomplete images
        lazyImageObserver.observe(img);
      }
    });

  }
  else { // If Intersection Observer isn't supported
    document.querySelectorAll('img[data-src], source[data-srcset]').forEach(function(img) {
      if (img.src) {
        img.src = img.dataset.src;
      }
      if (img.srcset) {
        img.srcset = img.dataset.srcset;
      }
    });
  }

}
