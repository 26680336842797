
// Get alt handle
// Takes product handle and alt type (metal/band), returns alt handle or nothing
function getAltHandle(handle, altType) {
  for (i = 0; i < rvProducts.length; i++) {
    if (rvProducts[i].handle == handle) {
      if (altType == 'metal') {
        return rvProducts[i].alt_metal_handle;
      }
      else if (altType == 'band') {
        return rvProducts[i].alt_band_handle;
      }
    }
  }
}
