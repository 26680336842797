
// Select from stack
$('#rv-selection-stack').on('click', '.ring-selection-item', function(){

	// Close info layer (if open)
	closeInfo();

	var thisRingSelectionItem = $(this);
  // Make current
  $('.ring-selection-item').removeClass('current');
  thisRingSelectionItem.addClass('current');
  // Get index of clicked
  var thisRingSelectionItemIndex = thisRingSelectionItem.index();
	var thisRingItem = $('#rv-interact-layer').children('.interact-item').eq(thisRingSelectionItemIndex);

  $('#rv-interact-layer').children('.interact-item').removeClass('interact-item-selected active');
  thisRingItem.addClass('interact-item-selected active');
  setTimeout( function() {
    thisRingItem.removeClass('active');
  },700);
  // Make interactable
  stopInteractable();
  makeInteractable();

	// Check if resizable
	if (thisRingItem.attr('data-resizable') == 'true') {
		$('#rv-resize-btn').removeClass('disabled');
	} else {
		$('#rv-resize-btn').addClass('disabled');
	}

	// Check metal
	if (thisRingItem.attr('data-alt-metal') == 'true') {
		$('#rv-metal-btn').removeClass('disabled');
	} else {
		$('#rv-metal-btn').addClass('disabled');
	}
	if (thisRingItem.attr('data-handle').indexOf('white-gold') >= 0) {
		$('#rv-metal-btn').addClass('white-gold');
	} else {
		$('#rv-metal-btn').removeClass('white-gold');
	}

	// Check band
	if (thisRingItem.attr('data-alt-band') == 'true') {
		$('#rv-band-btn').removeClass('disabled');
	} else {
		$('#rv-band-btn').addClass('disabled');
	}
	if (thisRingItem.attr('data-handle').indexOf('polished') >= 0) {
		$('#rv-band-btn').addClass('polished');
	} else {
		$('#rv-band-btn').removeClass('polished');
	}

});
