

interact('.resize-slider')
.draggable({
  origin: 'self',
  inertia: false,
  modifiers: [
    interact.modifiers.restrict({
      restriction: 'self'
    })
  ]
})
.on('dragmove', function (event) {  // call this listener on every dragmove

  // var thisSliderWidth = interact.getElementRect(event.target.parentNode).width;
  var thisSliderWidth = interact.getElementRect(event.target).width;
  var thisSliderVal = (event.pageX / thisSliderWidth).toFixed(2); // 2 decimal places (str)

  // Set position of slider handle and apply to attributes
  event.target.style.paddingLeft = (thisSliderVal * 100) + '%';
  event.target.setAttribute('data-slider-value', thisSliderVal);

	// Apply resize
  var thisRingItem = document.querySelector('.interact-item-selected');
	var minResizePercentage = 20; // smallest possible size will be this % of default
	var resizeAmount = (1-thisSliderVal)*minResizePercentage/2;
	thisRingItem.querySelector('.ring-part-band-left').style.transform = 'translateX(' + resizeAmount + '%)';
	thisRingItem.querySelector('.ring-part-band-right').style.transform = 'translateX(' + -resizeAmount + '%)';

	// Apply slider setting to item
	thisRingItem.setAttribute('data-resize-value', thisSliderVal);

	// Apply latest size as default size
	recentResizeValue = thisSliderVal;

});
