
/* ********

Product handles are the primary product ID data for this app, due to its consistency across multiple chupi sites.

URLs can take the params:
	?product=[handle]
	?currency=[currency-code]

*********/






// Fit container to viewport (fallback for CSS vw/vh properties on in-app browsers
$(window).on('load resize', function () {
  $('#rv-container').css('width', window.innerWidth);
  $('#rv-container').css('height', window.innerHeight);
});






getDeviceOS();






// Desktop QR Link
function desktopQr()  {
  if( window.innerWidth > desktopBreakPoint ) { // Desktop only
    // Create QR code (once only)
    if (qrGenerated == false) {
      // Ref: https://www.jqueryscript.net/other/qr-code-logo-label.html
      $("#rv-qr").qrcode({
        render: 'canvas',
        text: window.location.href,
        // label: 'test label',
        mode: 0, // normal
        // mode: 1, // label strip
        // mode: 2, // label boox
        // mode: 3, // image  strip
        // mode: 4, // image box
        //image: centreImage,
        size: 150,
        //mSize: 0.4, // image size with QR box
        ecLevel: 'L'
      });
      qrGenerated = true;
    }

    // Copy QR link
    function copyLink(target) {
      var rvLink = window.location.href;
      var el = document.createElement('textarea');
      el.value = rvLink;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }

    $('#rv-desktop').find('.copy-link').click(function() {
      var thisCopyLink = $(this);
      thisCopyLink.addClass('copied');
      setTimeout( function() {
        thisCopyLink.removeClass('copied');
      }, 2000);
      copyLink(thisCopyLink);
    });

  }
}
desktopQr();
$(window).resize ( function() {	desktopQr(); });





// Hide save/clear icon depending on OS
// if (deviceOS == 'iOS') {
// 	$('#rv-save-btn').hide();
// }
// else {
// 	$('#rv-clear-btn').hide();
// }





// Get RV products from JSON RV products
var rvProducts = [];
ajaxCall('GET', productsJsonPath, function(response){
  var parsedResponse = JSON.parse(response); // Parse AJAX data
  rvProducts = parsedResponse.products;
  //console.log(rvProducts);
  makeRingList(rvProducts);
  // Check for product handle in URL (appended when coming from Product page)
  if (window.location.href.indexOf('product=') >= 0) {
    urlProductHandle = window.location.href.split('product=')[1].split('/')[0].split('?')[0].split('&')[0].split('#')[0]; // term directly after product=
    if (getProductObjIndex(urlProductHandle) === undefined) { // check if handle matches JSON product object
      console.log('URL product handle does not match any products defined in JSON');
    } else {
      urlProductValid = true;
      reorderRingList(urlProductHandle);
    }
  }
});






// Check for currency in URL (appended when coming from Product page)
if (window.location.href.indexOf('currency=') >= 0) {
  urlCurrencyCode = window.location.href.split('currency=')[1].split('/')[0].split('?')[0].split('&')[0].split('#')[0]; // term directly after currency=
  currencyCode = urlCurrencyCode.toUpperCase();
	currencySymbol = getCurrencySymbol(currencyCode);
}






// Control buttons

// Open ring list
$('#rv-add-ring-btn').on('click', function(e) {
	if (interactItemCount < maxRings) { // max 5 rings
    closeInfo(); // Close info layer (if open)
    $('#rv-ring-list-drawer').toggleClass('active');
  }
});



// Add ring
$('#rv-ring-list-drawer').on('click','.product-card',function(e){
  // Close list
  $('#rv-ring-list-drawer').removeClass('active');
  resetRingList();
  // Make all existing interact items not selected
  $('#rv-interact-layer').children('.interact-item').removeClass('interact-item-selected active');
	// Add ring
	addRing($(this).attr('data-handle'));
});



// Capture button
$('#rv-capture-btn').on('click', function(e) {
	//$("#rv-upload-input").trigger('click'); // Trigger camera input
	// if (isGetUserMediaSupported()) {
	// 	$("#rv-capture-layer").addClass('active');
	// 	captureFromVideo();
	// } else {
		//$("#rv-upload-input").trigger('click'); // Trigger camera input
	//}

	var captureGuideCookie = getCookie('rv-capture-guide');
	if (captureGuideCookie !== "closed"){
    $('#capture-guide').find('img[data-src]').each( function() { // apply lazy loading on guide images
      $(this).attr('src', $(this).attr('data-src'));
    });
    setTimeout( function() {
      $('#capture-guide').addClass('active');
  		$('#capture-guide .btn').on('click', function(e) {
  			// Only trigger capture first time (cover screen)
  			if (showCaptureGuide == true) {
  				$("#rv-upload-input").trigger('click'); // Trigger camera input
  			}
  			setCookie('rv-capture-guide', 'closed', guideExpiryDays); // set cookie
  		});
	  },200);
	}
	else {
		$("#rv-upload-input").trigger('click'); // Trigger camera input
	}
	e.stopPropagation();
});




// Recapture button
$('#rv-recapture-btn').on('click', function(e) {

  closeInfo(); // Close info layer (if open)
	// Require confirmation of upload
  if ($(this).hasClass('confirm')) {
    if (confirm($(this).attr('data-confirm-message'))) {
    	$("#rv-upload-input").trigger('click');
    }
  }
  // Else trigger upload straight away
  else {
    $("#rv-upload-input").trigger('click');
  }
	e.stopPropagation();
});




// Clear button
// $('#rv-clear-btn').on('click', function(e) {
// 	// Button info
// 	if (infoLayerActive == true) {
// 		$('#rv-info-layer').find('.rv-info-panel').removeClass('active');
// 		$('#rv-info-layer').find('.rv-clear-btn-info').addClass('active');
// 	}
// 	// Button action
// 	else {
// 		$("#rv-control-layer").addClass('clear');
// 	  setTimeout( function() {
// 	    $("#rv-control-layer").removeClass('clear');
// 	  }, clearIconsDuration);
// 	}
// 	e.stopPropagation();
// });




// Save button
$('#rv-save-btn').on('click', function(e) {
  closeInfo();
  $('#rv-interact-layer').children('.interact-item').removeClass('active');
  if (deviceOS == 'iOS') {
    saveImageIOS();
  } else {
    saveImageAndroid();
  }
	e.stopPropagation();
});




// Open Edit Drawer
$('#rv-edit-btn').on('click', function(e) {
  closeInfo(); // Close info layer (if open)
	$('#rv-edit-drawer').addClass('active semi-active');
	$('#rv-interact-layer').find('.interact-item-selected').addClass('active');
});

// Toggle Edit Drawer height
$('#rv-edit-drawer').find('.drawer-header .more').on('click', function(e) {
	$('#rv-edit-drawer').toggleClass('semi-active');
	$(this).toggleClass('active');
	if ($(this).text() == $(this).attr('data-default-text')) {
		$(this).text($(this).attr('data-active-text'));
	} else {
		$(this).text($(this).attr('data-default-text'));
	}
});

function resetEditDrawer() {
	var moreButton = $('#rv-edit-drawer').find('.drawer-header .more');
	moreButton.removeClass('active');
	moreButton.text(moreButton.attr('data-default-text'));
	$('#rv-interact-layer').find('.interact-item-selected').removeClass('active');
}






// Open Tone controls
$('#rv-tone-btn').on('click', function(e) {
	$("#rv-control-layer").addClass('clear');
	$('#rv-interact-layer').find('.interact-item-selected').removeClass('active');
	$('#rv-edit-drawer').removeClass('active semi-active'); // Close Edit drawer
	if ($('#rv-base-image-container .filter-overlay').length == 0) { // add once only
		$('#rv-base-image-container').addClass('rv-filter').append('<div class="filter-overlay"></div>');
	}
  setTimeout( function() {
    $('#rv-tone-editor').addClass('active');
  }, 500);
});

// Select filter
$('#rv-filter-carousel').on('click', '.carousel-item', function(e) { // dynamically added
	$('#rv-filter-carousel').find('.carousel-item').removeClass('active');
	$(this).addClass('active');
	$('#rv-base-image-container').removeClass();
	var filterClass = 'rv-filter-' + $(this).attr('data-filter').toLowerCase();
	$('#rv-base-image-container').addClass('rv-filter');
	$('#rv-base-image-container').addClass(filterClass);
	// Get filter values
	var thisFilterValues = $(this).children('.rv-filter').css('filter');
	filterValues.brightness = thisFilterValues.split('brightness(')[1].split(')')[0];
	filterValues.contrast = thisFilterValues.split('contrast(')[1].split(')')[0];
	filterValues.saturate = thisFilterValues.split('saturate(')[1].split(')')[0];
	filterValues.hueRotate = thisFilterValues.split('hue-rotate(')[1].split('deg)')[0];
	filterValues.sepia = thisFilterValues.split('sepia(')[1].split(')')[0];
	filterValues.overlayOpacity = $(this).find('.filter-overlay').css('opacity');
	// Apply filters to base image
	applyFilters(filterValues, $('#rv-tone-editor .inner').find('.filter-slider').attr('data-slider-value'));
	// Disable slider for no-filter
	if ($(this).attr('data-filter') !== 'No filter') {
		$('#rv-tone-editor .inner').find('.filter-slider').removeClass('disabled');
	} else {
		$('#rv-tone-editor .inner').find('.filter-slider').addClass('disabled');
	}
});

// Close Tone controls
$('#rv-tone-editor').on('click', function(e) {
  var inner = $(this).children('.inner');
	//var carosuel = $(this).children('.carousel');
  if (!inner.is(e.target) && inner.has(e.target).length === 0) {
    $(this).removeClass('active');
    setTimeout( function() {
      $("#rv-control-layer").removeClass('clear');
    }, 500);
  }
  else {
    e.preventDefault();
  }
});




// Metal button
$('#rv-metal-btn').on('click', function(e) {
	$(this).toggleClass('white-gold');
	e.stopPropagation();

	// Switch ring item
	var thisRingItem = $('#rv-interact-layer').find('.interact-item-selected');
	// Get current ring item handle
	var thisRingItemHandle = thisRingItem.attr('data-handle');
	// Find alt metal handle
	var altMetalHandle = getAltHandle(thisRingItemHandle, 'metal');
	thisRingItem.attr('data-handle', altMetalHandle); // Update handle to alt metal handle
	// Find alt metal product obj by handle
	for (j = 0; j < rvProducts.length; j++) {
    if (rvProducts[j].handle == altMetalHandle) {
			// Switch ring item image
			thisRingItem.find('img.default').attr('src', productImagesPath + rvProducts[j].image_front_cut);
			// Switch ring part images
			if (checkResizable(altMetalHandle) == true) {
				thisRingItem.find('img.ring-part-band-left').attr('src', productImagesPath + rvProducts[j].image_part_band_left);
				thisRingItem.find('img.ring-part-band-right').attr('src', productImagesPath + rvProducts[j].image_part_band_right);
				thisRingItem.find('img.ring-part-setting').attr('src', productImagesPath + rvProducts[j].image_part_setting);
				$('#rv-resize-btn').removeClass('disabled');
			} else {
				$('#rv-resize-btn').addClass('disabled');
			}
      // Check new alt metal
      if (getAltHandle(altMetalHandle, 'metal') == '') {
        $('#rv-metal-btn').addClass('disabled');
      } else {
        $('#rv-metal-btn').removeClass('disabled');
      }
      // Check new alt band
      if (getAltHandle(altMetalHandle, 'band') == '') {
        $('#rv-band-btn').addClass('disabled');
      } else {
        $('#rv-band-btn').removeClass('disabled');
      }
			// Switch selection stack image
			$('#rv-selection-stack').find('.ring-selection-item.current .inner').css('background-image', 'url(' + productImagesPath + rvProducts[j].image_front_cut + ')');
      break;
    }
  }
	// Update CTA item
	updateCtaList(thisRingItemHandle, 'replace', altMetalHandle);

});




// Band button
$('#rv-band-btn').on('click', function(e) {
	$(this).toggleClass('polished');
	e.stopPropagation();

	// Switch ring item
	var thisRingItem = $('#rv-interact-layer').find('.interact-item-selected');
	// Get current ring item handle
	var thisRingItemHandle = thisRingItem.attr('data-handle');
	// Find product obj by handle
	var altBandHandle = getAltHandle(thisRingItemHandle, 'band');
	thisRingItem.attr('data-handle', altBandHandle); // Update handle to alt band handle
	// Find alt band product obj by handle
	for (j = 0; j < rvProducts.length; j++) {
    if (rvProducts[j].handle == altBandHandle) {
			// Switch ring item image
			thisRingItem.find('img.default').attr('src', productImagesPath + rvProducts[j].image_front_cut);
			// Switch ring part images
			if (checkResizable(altBandHandle) == true) {
				thisRingItem.find('img.ring-part-band-left').attr('src', productImagesPath + rvProducts[j].image_part_band_left);
				thisRingItem.find('img.ring-part-band-right').attr('src', productImagesPath + rvProducts[j].image_part_band_right);
				thisRingItem.find('img.ring-part-setting').attr('src', productImagesPath + rvProducts[j].image_part_setting);
				$('#rv-resize-btn').removeClass('disabled');
			} else {
				$('#rv-resize-btn').addClass('disabled');
			}
      // Check new alt metal
      if (getAltHandle(altBandHandle, 'metal') == '') {
        $('#rv-metal-btn').addClass('disabled');
      } else {
        $('#rv-metal-btn').removeClass('disabled');
      }
      // Check new alt band
      if (getAltHandle(altBandHandle, 'band') == '') {
        $('#rv-band-btn').addClass('disabled');
      } else {
        $('#rv-band-btn').removeClass('disabled');
      }
			// Switch selection stack image
			$('#rv-selection-stack').find('.ring-selection-item.current .inner').css('background-image', 'url(' + productImagesPath + rvProducts[j].image_front_cut + ')');
      break;
    }
  }
	// Update CTA item
	updateCtaList(thisRingItemHandle, 'replace', altBandHandle);

});





// Open Resize controls
$('#rv-resize-btn').on('click', function(e) {

	$("#rv-control-layer").addClass('clear');
	// Close Edit drawer
	$('#rv-edit-drawer').removeClass('active semi-active');
	//resetEditDrawer();
  setTimeout( function() {
    $('#rv-resize-editor').addClass('active');
  }, 400);
	var thisRingItem = $('#rv-interact-layer').find('.interact-item-selected');
	//var thisRingItemIndex = $('#rv-interact-layer').find('.interact-item').index(thisRingItem);
	//thisRingItem.addClass('active'); // highlight active ring for resizing
	// Show ring-part images and apply existing filters
	//thisRingItem.find('img').css('filter', 'brightness(' + brightnessVal + ') contrast(' + contrastVal + ') saturate(' + saturationVal + ') drop-shadow(0px 4px 12px rgba(0,0,0,' + shadowVal + '))');
	thisRingItem.find('img.ring-part').show();
	thisRingItem.find('img.default').hide();
	// Attach resize slider to current ring
	//$('#rv-resize-editor').find('.resize-slider').attr('data-ring-item-index', thisRingItemIndex);
	// Manage metal button state
	var altMetalHandle = getAltHandle(thisRingItem.attr('data-handle'), 'metal');
	if (altMetalHandle) {
	if (checkResizable(altMetalHandle) == true) {
			$('#rv-metal-btn').removeClass('disabled');
		} else {
			$('#rv-metal-btn').addClass('disabled');
		}
	}
	// Manage band button state
	var altBandHandle = getAltHandle(thisRingItem.attr('data-handle'), 'band');
	if (altBandHandle) {
		if (checkResizable(altBandHandle) == true) {
			$('#rv-band-btn').removeClass('disabled');
		} else {
			$('#rv-band-btn').addClass('disabled');
		}
	}
	// Get resize value for current item and apply to slider
	var thisRingResizeValue = thisRingItem.attr('data-resize-value');
	if (thisRingResizeValue == undefined) {
		thisRingResizeValue = recentResizeValue;
	}
	$('#rv-resize-editor').find('.resize-slider').attr('data-slider-value', thisRingResizeValue);
	$('#rv-resize-editor').find('.resize-slider').css('padding-left', (thisRingResizeValue * 100) + '%');

});

// Close Resize controls
$('#rv-resize-editor').on('click', function(e) {
  var inner = $(this).children('.inner');
  if (!inner.is(e.target) && inner.has(e.target).length === 0) {
    $(this).removeClass('active');
    setTimeout( function() {
      $("#rv-control-layer").removeClass('clear');
    }, 200);
		$('#rv-interact-layer').find('.interact-item').removeClass('active');
		//$('#rv-resize-editor').find('.resize-slider').attr('data-ring-item-index', ''); // reset ring index
  }
  else {
    e.preventDefault();
  }
});




// Open CTA drawer
$('#rv-cta-btn').on('click', function(e) {
	if (interactItemCount > 0) { // max 5 rings
    closeInfo(); // Close info layer (if open)
    $('#rv-cta-drawer').toggleClass('active');
  }
});

// Currency switch toggle (show/hide)
$('.currency-select .currency-switch').on('click', function(e) {
	$(this).closest('.currency-select').toggleClass('active');
});

// Currency option select
$('.currency-select .currency-option').on('click', function(e) {
	applyCurrency($(this).attr('data-currency'));
	$(this).closest('.currency-select').removeClass('active');
});

// Click CTA item link
$('#rv-cta-drawer').on('click', '.store-link', function(e) { // dynamically added link
	var handle = $(this).closest('.cta-item').attr('data-handle');
	var ctaLinkURL = storeURL + 'products/' + handle + utm + '&currency=' + currencyCode;
	window.open(ctaLinkURL, '_blank'); // open product in store on new tab
});





// Open Info layer
$('#rv-info-btn').on('click', function(e) {
	infoLayerActive = true;
	$(this).addClass('disabled');
  $("#rv-info-layer").addClass('active');
	$('#rv-control-layer #rv-add-ring-btn').addClass('no-highlight'); // Disable highlight
  $('#rv-control-buttons').addClass('info-display');
	//$('#rv-remove-btn').addClass('active');
});

function closeInfo() {
  infoLayerActive = false;
  $('#rv-info-layer').removeClass('active');
  $('#rv-info-btn').removeClass('disabled');
	$('#rv-control-layer #rv-add-ring-btn').removeClass('no-highlight'); // Re-enable highlight
  $('#rv-control-buttons').removeClass('info-display');
}

// Close Info layer
$('#rv-info-layer').on('click', function(e) {
	// Confine event to layer excluding inner panels
	if (e.target == this) {
		closeInfo();
  }
});

// Open remove ring info
// $('#rv-remove-btn').on('click', function(e) {
// 	// Button info
// 	if (infoLayerActive == true) {
// 		$('#rv-info-layer').find('.rv-info-panel').removeClass('active');
// 		$('#rv-info-layer').find('.rv-remove-ring-btn-info').addClass('active');
// 	}
// });

// Open capture guide (info panel)
// $('#rv-info-layer .rv-recapture-btn-info').find('button').on('click', function(e) {
// 	$('#capture-guide').addClass('active');
// });
//
// // Open gesture guide (info panel)
// $('#rv-info-layer .rv-add-ring-btn-info, #rv-info-layer .rv-selection-stack-info').find('button').on('click', function(e) {
// 	$('#gesture-guide').addClass('active');
// });












// Close overlay (button)
$('.rv-overlay').find('.close').on('click', function(e) {
	$(this).closest('.rv-overlay').removeClass('active');
});

// Close overlay (outside of modal)
$('.rv-overlay').on('click', function(e) {
  if (e.target == this) {
    this.classList.remove('active');
  }
  else {
    e.preventDefault();
  }
});



// Close drawer (button)
$('.rv-drawer').find('.drawer-header .close-btn').on('click', function(e) {
	$(this).closest('.rv-drawer').removeClass('active');
	// Reset More toggle button
	if ($(this).closest('#rv-edit-drawer').length) {
		resetEditDrawer();
	} else if ($(this).closest('#rv-cta-drawer').length) {
		$('#rv-cta-drawer').find('.currency-select').removeClass('active');
	}
});

// Close drawer (outside of modal)
$('.rv-drawer').on('click', function(e) {
  if ($(e.target).is($(this))) {
    $(this).removeClass('active');
		// Reset More toggle button
		if ($(this).closest('#rv-edit-drawer').length) {
			resetEditDrawer();
		} else if ($(this).closest('#rv-cta-drawer').length) {
			$('#rv-cta-drawer').find('.currency-select').removeClass('active');
		}
  }
  else {
    e.preventDefault();
  }
});
