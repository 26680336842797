


// Set init position of ring overlay
// var ringOverlay = $('.interact-item')[0];
// var ringOverlayWidth = ringOverlay.getBoundingClientRect().width;
// var ringOverlayHeight = ringOverlay.getBoundingClientRect().height;

// Set init position of ring overlay
// ringOverlay.style.left = (window.innerWidth/2 - ringOverlayWidth/2)  + "px";
// ringOverlay.style.top = (window.innerHeight/2 - ringOverlayHeight/2)  + "px";

// Set height of container
//$('#rv-container').css('height', window.innerHeight);






// Make filter thumbnails
function  makeFilterThumbnails() {

  var filterCarouselTrack = $('#rv-filter-carousel').find('.carousel-track');
  // Delete any existing thumbnails
  if (filterCarouselTrack.children().length) {
    filterCarouselTrack.children().remove();
  }
  function  makeFilterThumbnail(filterName) {
    var filterCarouselItemImage = '<img class="object-cover" src="' + baseImageSrc + '">';
    var filterClass = 'rv-filter-' + filterName.toLowerCase().replace(' ', '-');
    var filterCarouselItemOverlay = '<div class="filter-overlay"></div>';
    filterCarouselItemImage = '<div class="image rv-filter ' + filterClass + '">' + filterCarouselItemImage + filterCarouselItemOverlay + '</div>';
    var filterCarouselItemLabel = '<label>' + filterName + '</label>';
    // Append
    filterCarouselTrack.append('<div class="carousel-item" data-filter="' + filterName + '">' + filterCarouselItemImage + filterCarouselItemLabel + '</div>');
  }
  for (i = 0; i < imageFilters.length; i++) {
    makeFilterThumbnail(imageFilters[i]);
  }
  filterCarouselTrack.children('.carousel-item').eq(0).addClass('active');

}







// Create base image from camera input
// Ref: https://stackoverflow.com/questions/10906734/how-to-upload-image-into-html5-canvas#answer-10906961
var imageLoader = document.getElementById('rv-upload-input');
    imageLoader.addEventListener('change', handleImage, false);
var baseImageContainer = document.getElementById('rv-base-image-container');
// var canvas = baseImageContainer.getElementsByTagName('canvas')[0];
// var ctx = canvas.getContext('2d');

//var imageOrientation = "";

function handleImage(e){
	if (baseImageContainer.getElementsByTagName('img').length > 0) {
		baseImageContainer.getElementsByTagName('img')[0].remove();
	}
  var reader = new FileReader();
  reader.onload = function(event){

    var img = new Image();
    img.onload = function(){
			baseImageContainer.prepend(img);
      imageCenterCover(img);
    };
    img.src = event.target.result;
    baseImageSrc = event.target.result; // Base64

    makeFilterThumbnails(baseImageSrc); // Create image filter thumbnails from captured image

  };
  reader.readAsDataURL(e.target.files[0]);


  function imageCenterCover(img) {
    var originalWidth = img.width;
    var originalHeight = img.height;
    var newHeight = window.innerHeight;
    var newWidth = originalWidth * (newHeight/originalHeight); // maintain ratio
    var newTop = 0;

    if (newWidth < window.innerWidth) {
      newHeight = newHeight * (window.innerWidth/newWidth);
      newWidth = window.innerWidth;
      newTop = -(newHeight-window.innerHeight)/2;
    }

    var newLeft = -(newWidth-window.innerWidth)/2;

    img.width = newWidth;
    img.height = newHeight;
    img.style.left = newLeft + 'px';
    img.style.top = newTop + 'px';
  }






	resetAllOnCapture();

}
