

// Determine the mobile operating system
// https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
function getDeviceOS() {

  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    deviceOS = "Windows";
    //$('.device-os').text("Windows OS");
  }

  if (/android/i.test(userAgent)) {
    deviceOS = "Android";
    //$('.device-os').text("Android");
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    deviceOS = "iOS";
    //$('.device-os').text("iOS");
  }

}







// Check for user device media support
function isGetUserMediaSupported() {
  return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
    || !!(navigator.getUserMedia)
    || !!(navigator.webkitGetUserMedia)
    || !!(navigator.mozGetUserMedia);
}
