
// Search results
function ringListSearchResults(rvSearchTerm, ringListMetalFilter) {

  // Only apply results when there's a search term OR an active metal filter
  if (rvSearchTerm.length > 1 || ringListMetalFilter !== '') {

    var listItemCount = $('#rv-ring-list-drawer').find('.grid-item').length;
    var hideCount = 0;
    var termLowercase = rvSearchTerm.toLowerCase();

    $('#rv-ring-list-drawer').find('.grid-item').each( function() {
      var thisGridItem = $(this);
      thisGridItem.removeClass('search-hide');

      var productTitle = '';
      var productHandle = thisGridItem.children('.product-card').attr('data-handle');
      for (i = 0; i < rvProducts.length; i++) {
        if (rvProducts[i].handle == productHandle) {
          productTitle = rvProducts[i].title.toLowerCase();
          productTags = rvProducts[i].tags.toLowerCase();
          break;
        }
      }

      function hideUnmatched() {
        thisGridItem.addClass('search-hide');
        $('#rv-ring-list-drawer').find('.drawer-main .grid h3').addClass('search-hide');
        hideCount += 1;
      }
      // Hide if no match in title or tags
      if (productTitle.indexOf(termLowercase) < 0 && productTags.indexOf(termLowercase) < 0) {
        hideUnmatched();
      }
      // Hide if metal filter active and metal not in title
      else if (ringListMetalFilter !== '' && productHandle.indexOf(ringListMetalFilter) < 0) {
        hideUnmatched();
      }

    });

    // No results
    if (listItemCount == hideCount) {
      $('#rv-ring-list-drawer').find('.no-results').show();
      $('#rv-ring-list-drawer').find('.no-results .search-term').text(rvSearchTerm);
      // Apply metal to no results message
      if (ringListMetalFilter == 'solid-gold') {
        $('#rv-ring-list-drawer').find('.no-results .no-results-metal').removeClass('metal-white-gold').addClass('metal-gold').text('solid gold ');
      } else if (ringListMetalFilter == 'solid-white-gold') {
        $('#rv-ring-list-drawer').find('.no-results .no-results-metal').removeClass('metal-gold').addClass('metal-white-gold').text('solid white gold ');
      } else {
        $('#rv-ring-list-drawer').find('.no-results .no-results-metal').removeClass('metal-gold metal-white-gold').text('');
      }
    } else {
      $('#rv-ring-list-drawer').find('.no-results').hide();
    }

  }
  // Reset results
  else {
    $('#rv-ring-list-drawer').find('.grid-item').removeClass('search-hide');
    $('#rv-ring-list-drawer').find('.drawer-main .grid h3').removeClass('search-hide');
    $('#rv-ring-list-drawer').find('.no-results').hide();
  }

} // end ringListSearchResults()



// Reset ring list (search, filter, results)
function resetRingList() {
  $('#rv-ring-list-drawer').find('.grid-item').removeClass('search-hide');
  $('#rv-ring-list-drawer').find('.drawer-main .grid h3').removeClass('search-hide');
  $('#rv-ring-list-drawer').find('.no-results').hide();
  $('#rv-ring-list-search').find('input[type="search"]').val('');
  $('#rv-ring-list-drawer').find('.drawer-main').animate({scrollTop: '0px'}, 0); // scroll list back to top
  $('#rv-ring-list-metal-filter').find('.metal-switch').removeClass('active');
  rvSearchTerm = '';
  ringListMetalFilter = '';
}



// Ring List search
$('#rv-ring-list-search').find('input[type="search"]').on('keyup', function() {
  rvSearchTerm = $(this).val(); // Assign search term
  ringListSearchResults(rvSearchTerm, ringListMetalFilter); // Apply results
});



// Ring List metal filter
$('#rv-ring-list-metal-filter').find('.metal-switch').on('click', function() {
  // Toggle switches
  $('#rv-ring-list-metal-filter').find('.metal-switch').not($(this)).removeClass('active');
	$(this).toggleClass('active');
  // Assign selected metal
  if ($(this).hasClass('active')) {
    ringListMetalFilter = $(this).attr('data-metal');
  } else {
    ringListMetalFilter = ''; // reset
  }
  ringListSearchResults(rvSearchTerm, ringListMetalFilter); // Apply results
});
