
function resetAllOnCapture() {
	// Reset rings
  //$('#rv-interact-layer').empty();
	$('#rv-interact-layer').find('.interact-item').remove();
  $('#rv-selection-stack').empty();
  interactItemCount = 0;
	ringItemRecentAngle = ringItemDefaultAngle;
	ringItemRecentScale = ringItemDefaultScale;
	recentResizeValue = deafultResizeValue;
	// Reset base image filter
	$('#rv-base-image-container').removeClass().attr('style', '');
	$('#rv-base-image-container .filter-overlay').remove();
  // Reset layers
  $('#rv-cover-layer').removeClass('active').addClass('kill-animation');
	$('#rv-info-layer').removeClass('rings-active');
  //$('#rv-canvas-layer').addClass('active');
  $('#rv-interact-layer').addClass('active');
	showCaptureGuide = false;
	// Reset CTA drawer
	$('#rv-cta-drawer').find('.drawer-main .grid .grid-item').remove();
  // Reset controls
  $('#rv-control-layer').addClass('active');
  $('#rv-add-ring-btn').removeClass('disabled').addClass('highlight'); // Enable add-ring button
  $('#rv-recapture-btn').removeClass('confirm');
  //$('#rv-tone-btn, #rv-clear-btn, #rv-save-btn, #rv-resize-btn').removeClass('active');
	$('#rv-save-btn, #rv-edit-btn, #rv-cta-btn').addClass('disabled');
  // Reset sliders
  $('#rv-tone-editor').find('.filter-slider').css('padding-left', '100%').attr('data-slider-value', 1);
  // Reset filters
	filterValues.brightness = brightnessDefaultVal;
	filterValues.contrast = contrastDefaultVal;
	filterValues.saturate = saturationDefaultVal;
	filterValues.hueRotate = hueRotateDefaultVal;
	filterValues.sepia = sepiaDefaultVal;
	filterValues.overlayOpacity = filterOverlayOpacityDefaultVal;
	// If URL product handle is valid, automatically add this ring on load
	if (urlProductValid == true) {
		addRing(urlProductHandle);
		$('#rv-add-ring-btn').removeClass('highlight');
	}
	// Ring list images
	if (ringListLazyLoaded == false) {
		var ringListDrawer = document.getElementById('rv-ring-list-drawer');
		ringListDrawerMain = ringListDrawer.querySelector('.drawer-main');
    lazyImages(ringListDrawerMain, ringListLazyMargin);
		ringListLazyLoaded = true; // prevent call on recapture
	}
}
