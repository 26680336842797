

function updateRingList(handle, action, replacingHandle) {



  // Apply action
  if (action == 'add') {
    addCtaItem(handle);
  }
  else if (action == 'remove') {
    removeCtaItem(handle);
  }
  else if (action == 'replace') {
    removeCtaItem(handle);
    addCtaItem(replacingHandle);
  }


} // end updateRingList()
