
// Call interction functions

function dragMoveListener (event) {
	event.target.classList.add('active');

  var target = event.target,
  // keep the dragged position in the data-x/data-y attributes
  activeItemX = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
  activeItemY = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

  // translate the element
  target.style.webkitTransform =
  target.style.transform = 'translate(' + activeItemX + 'px, ' + activeItemY + 'px)';

  // update the posiion attributes
  target.setAttribute('data-x', activeItemX);
  target.setAttribute('data-y', activeItemY);


  // If dragged outside viewport...
  var displacedX = Math.abs(activeItemX);
  var displacedY = Math.abs(activeItemY);
	// If half or more of ring item is outside viewport
  if (displacedX >= (window.innerWidth/2) || displacedY >= (window.innerHeight/2)) {

    if (!itemInMotion) { // Call once only per motion
			removeRingItem();
    }

  }

} // end dragMoveListener()







// Interactable functions

function makeInteractable(event) {

  var angleScale = {
    angle: ringItemRecentAngle,
    scale: ringItemRecentScale, // initial scale set in CSS
  };

  interact('.interact-item-selected').gesturable({

   onstart: function (event) {
     angleScale.angle -= event.angle;
   },

   onmove: function (event) {

     // Make active on move
     event.target.classList.add('active');

     // Define new angle and scale
     var currentAngle = event.angle + angleScale.angle;
     var currentScale = event.scale * angleScale.scale;

     //document.querySelector('.device-os').textContent(currentScale);

     // Restrict scale
     // if (currentScale > 3) { currentScale = 3; }
     // else if (currentScale < 0.5) { currentScale = 0.5; }
     if (currentScale > 1) { currentScale = 1; }
     else if (currentScale < 0.165) { currentScale = 0.165; }

     var scaleElement = null;
     for (var i = 0; i < event.target.childNodes.length; i++) {
        if (event.target.childNodes[i].className == "scale-element") {
          scaleElement = event.target.childNodes[i];
          break;
        }
     }

     // Apply angle and scale changes
     scaleElement.style.webkitTransform =
     scaleElement.style.transform = 'rotate(' + currentAngle + 'deg)' + 'scale(' + currentScale + ')';

		 // Update recent angle and scale
		 scaleElement.setAttribute('data-angle', currentAngle);
		 scaleElement.setAttribute('data-scale', currentScale);
		 ringItemRecentAngle = currentAngle;
		 ringItemRecentScale = currentScale;

     // Allow drag while gesturing
     dragMoveListener(event);

   },

   onend: function (event) {

     event.target.classList.remove('active');

     angleScale.angle = angleScale.angle + event.angle;
     angleScale.scale = angleScale.scale * event.scale;

     // if (angleScale.scale > 3) { angleScale.scale = 3; }
     // else if (angleScale.scale < 0.5) { angleScale.scale = 0.5; }
     if (angleScale.scale > 1) { angleScale.scale = 1; }
     else if (angleScale.scale < 0.165) { angleScale.scale = 0.165; }

   }

  }).draggable({
    inertia: {
      resistance: 20
      // minSpeed: 200,
      // endSpeed: 100
    },
		// modifiers: [
    //   interact.modifiers.restrictRect({
    //     restriction: 'parent' // keep within parent on release (bounce back)
    //     endOnly: true // allow drag outside parent
    //   })
    // ],
    onmove: dragMoveListener,
    onend: function (event) {
      //console.log('stop');
      event.target.classList.remove('active');
			//document.getElementById('rv-remove').classList.remove('active'); // Show trash icon
      itemInMotion = false; // Reset motion status
   }
 });

  function reset() {
    scaleElement.style.webkitTransform = scaleElement.style.transform = 'scale(1)';
    angleScale.angle = ringItemDefaultAngle;
    angleScale.scale = ringItemDefaultScale;
  }

} // end makeInteractable()






// Stop interactions
function stopInteractable(event) {
  interact('.interact-item-selected').unset();
} // end stopInteractable()






// Remove ring via dropzone (trash icon)
interact('#rv-remove').dropzone({
  accept: '.interact-item',
  overlap: removeItemOverlap,

  ondropactivate: function (event) { // when item starts drag
		event.target.classList.add('active');
  },
  ondragenter: function (event) { // when item enters dropzone
		event.relatedTarget.classList.add('removing');
		event.target.classList.add('highlight');
  },
  ondragleave: function (event) { // when item leaves dropzone
		event.relatedTarget.classList.remove('removing');
		event.target.classList.remove('highlight');
  },
  ondrop: function (event) { // when item is dropped in dropzone
		event.relatedTarget.classList.add('removed');
		event.target.classList.remove('active', 'highlight');
		if (!itemInMotion) { // Call once only per motion
			removeRingItem();
    }
  },
  ondropdeactivate: function (event) { // when item ends drag
		event.target.classList.remove('active');
  }
});
