
// Host
var rvHostRoot = window.location.href.split('?')[0]; // URL excluding query params
rvHostRoot = rvHostRoot.split('index.html')[0]; // URL excluding index.html
var productImagesPath = rvHostRoot + "img/products/";
var lazyPlaceholderImagePath = rvHostRoot + "img/misc/lazy-image-placeholder.png";
var productsJsonPath = rvHostRoot + "rv-products.json";
// For local dev use different product JSON and images
// var localhostPort = 8888;
// if (window.location.href.indexOf('localhost') >= 0 || window.location.href.indexOf(localhostPort) >= 0) {
//   productsJsonPath = rvHostRoot + "rv-products-dev.json";
//   productImagesPath = rvHostRoot + "img/products-dev/";
// }
if (window.location.href.indexOf('dev=true') >= 0) { // Check for dev param in URL
  productsJsonPath = rvHostRoot + "rv-products-dev.json";
  productImagesPath = rvHostRoot + "img/products-dev/";
}


// Store
var defaultStoreURL = "https://www.chupi.com/";
var storeURL = defaultStoreURL;
var ukStoreURL = "https://uk.chupi.com/";


// System
navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
var deviceOS = "";


// Window
var windowWidth = window.innerWidth;
var windowHeight = window.innerHeight;
var desktopBreakPoint = 991;
var qrGenerated = false;


// Image capture
var baseImageSrc =  '';
var imageFilters = ['No filter', '1977', 'Aden', 'Amaro', 'Brannan', 'Brooklyn', 'Clarendon', 'Earlybird', 'Gingham', 'Hudson', 'Lofi', 'Maven', 'Perpetua', 'Reyes', 'Stinson', 'Toaster', 'Walden', 'Valencia', 'Xpro2'];


// Currency
var defaultCurrencyCode = 'EUR';
var currencyCode = defaultCurrencyCode;
var defaultCurrencySymbol = '€';
var currencySymbol = defaultCurrencySymbol;


// Ring List
var validProductStatus =  ['Active', 'Coming soon'];
var urlProductValid = false;
var urlProductHandle = '';
var ringListRelatedProductsHeading = 'Recommended pairing';
var ringListMoreProductsHeading = 'More rings to try';
var rvSearchTerm = '';
var ringListMetalFilter = '';
var ringListLazyLoaded = false;
var ringListLazyMargin = '0px 0px ' + window.innerHeight + 'px 0px';


// Guide modals
var showCaptureGuide = true;
var showGestureGuide = true;
var guideExpiryDays = 30;


// Info layer init
var infoLayerActive = false;


// Filters
var brightnessDefaultVal = 1;
var contrastDefaultVal = 1;
var saturationDefaultVal = 1;
var hueRotateDefaultVal = 0;
var sepiaDefaultVal = 0;
var filterOverlayOpacityDefaultVal = 0;
//var shadowDefaultVal = 0.3; // rgba alpha value
//var shadowRange = 0.3; // Plus/minus from default
//var shadowVal = shadowDefaultVal; // Set initial value
var filterValues = {
  'brightness': brightnessDefaultVal,
  'contrast': contrastDefaultVal,
  'saturation': saturationDefaultVal,
  'hueRotate': hueRotateDefaultVal,
  'sepia': sepiaDefaultVal,
  'overlayOpacity': filterOverlayOpacityDefaultVal
};


// Resizing
var deafultResizeValue = 1;
var recentResizeValue = deafultResizeValue;


// Clear icons
var clearIconsDuration = 3000;


// Interact
//var addedRingHandles = [];
var interactItemCount = $('.interact-item').length;
var maxRings = 5;
var activeItemX = 0;
var activeItemY = 0;
var itemInMotion = false;
var ringItemDefaultScale = 0.33; // initial scale set in CSS
var ringItemRecentScale = ringItemDefaultScale;
var ringItemDefaultAngle = 0;
var ringItemRecentAngle = ringItemDefaultAngle;

// Removing
var removeItemOverlap = 0.25; // this much of the item must be in the dropzone


// CTA
var ctaItemLinkText = 'View on site';
var ctaItemComingSoonText = 'Coming soon';
var utm = '?utm_source=vto&utm_medium=vto_your_rings&utm_campaign=vto';


// Image saving
var downloadWatermark = true;  // Adds Chupi logo to bottom of download image
var downloadFilePrefix = 'chupi-virtual-try-on'; // gets appended with timestamp
